export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: 'Страница безопасной оплаты',
      description: 'Мы надежно шифруем вашу финансовую информацию',
      methodErrorTips: 'Извините, выбранная вами страна в настоящее время не поддерживается. Пожалуйста, выберите другую страну и попробуйте снова.',
      recommendation: 'Рекомендация',
      cardNumber: 'Номер карты',
      cardName: 'Имя на карте',
      cvv: 'CVV',
      expiredDate: 'Срок действия',
      billingInfo: 'Платежная информация',
      country: 'Страна',
      state: 'Штат',
      firstName: 'Имя',
      lastName: 'Фамилия',
      document: 'Документ',
      city: 'Город',
      address: 'Адрес',
      streetNumber: 'Номер улицы',
      zipCode: 'Почтовый индекс',
      email: 'Эл. почта',
      phone: 'Номер телефона',
      pay: 'ОПЛАТИТЬ',
      choosePaymentMethod: 'Выберите способ оплаты',
      continue: 'Продолжить',
      later: 'Позже',
      returnToMerchant: 'Отмена и возврат к продавцу',
      resultToMerchant: 'Возврат к продавцу',
      orderId: 'Номер заказа',
      orderAmount: 'Сумма заказа',
      billingDescriptor: 'Платежное описание',
      goodsName: 'Название товара',
      timeLimit: 'Пожалуйста, завершите оплату в течение указанного времени, иначе страница истечет',
      otherPaymentMethods: 'Другие способы оплаты',
      close: 'Закрыть',
      paymentErrorTitle: 'Оплата не удалась',
      paymentErrorTip: 'Ваш платеж не был успешно обработан. Пожалуйста, попробуйте еще раз.',
      paymentFailed: 'Процесс оплаты не удался',
      continueShopping: 'Продолжить покупки',
      paymentSuccess: 'Процесс оплаты успешно завершен',
      paymentPending: 'Платеж отправлен, подтверждение',
      correctDateTip: 'Пожалуйста, введите действительную дату истечения срока',
      correctCardNumberTip: 'Пожалуйста, введите действительный номер карты',
      correctSecurityCodeTip: 'Пожалуйста, введите действительный код безопасности',
      mobileMoneyOperator: 'Оператор мобильных денег',
      bank: 'Банк',
      correctEmailAddressTip: 'Пожалуйста, введите действительный адрес электронной почты'
    },
    common: {
      uploadImgErrorTip: 'Разрешено загружать только изображения',
      inputMessageTip: 'Пожалуйста, заполните содержимое',
      sendSuccessTip: 'Отправка успешна',
      sendErrorTip: 'Отправка не удалась',
      inputPlaceholder: 'Пожалуйста, введите {field}',
      selectPlaceholder: 'Пожалуйста, выберите {field}'
    }
  }
}
