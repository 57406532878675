export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: 'Bezpieczna strona płatności',
      description: 'Bezpiecznie szyfrujemy Twoje informacje finansowe',
      methodErrorTips: 'Przepraszamy, wybrany przez Ciebie kraj nie jest obecnie obsługiwany. Proszę wybrać inny kraj i spróbować ponownie.',
      recommendation: 'Rekomendacja',
      cardNumber: 'Numer karty',
      cardName: 'Nazwa na karcie',
      cvv: 'CVV',
      expiredDate: 'Data ważności',
      billingInfo: 'Informacje rozliczeniowe',
      country: 'Kraj',
      state: 'Stan',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      document: 'Dokument',
      city: 'Miasto',
      address: 'Adres',
      streetNumber: 'Numer ulicy',
      zipCode: 'Kod pocztowy',
      email: 'E-mail',
      phone: 'Numer telefonu',
      pay: 'PŁAĆ',
      choosePaymentMethod: 'Wybierz metodę płatności',
      continue: 'Kontynuuj',
      later: 'Później',
      returnToMerchant: 'Anuluj i wróć do sprzedawcy',
      resultToMerchant: 'Wróć do sprzedawcy',
      orderId: 'ID zamówienia',
      orderAmount: 'Kwota zamówienia',
      billingDescriptor: 'Opis faktury',
      goodsName: 'Nazwa towaru',
      timeLimit: 'Proszę zakończyć płatność w wyznaczonym czasie, w przeciwnym razie strona zostanie zamknięta',
      otherPaymentMethods: 'Inne metody płatności',
      close: 'Zamknij',
      paymentErrorTitle: 'Płatność nie powiodła się',
      paymentErrorTip: 'Twoja płatność nie została pomyślnie przetworzona. Proszę spróbować ponownie.',
      paymentFailed: 'Proces płatności nie powiódł się',
      continueShopping: 'Kontynuuj zakupy',
      paymentSuccess: 'Proces płatności zakończony pomyślnie',
      paymentPending: 'Płatność wysłana, potwierdzenie',
      correctDateTip: 'Proszę wprowadzić ważną datę wygaśnięcia',
      correctCardNumberTip: 'Proszę wprowadzić ważny numer karty',
      correctSecurityCodeTip: 'Proszę wprowadzić prawidłowy kod zabezpieczający',
      mobileMoneyOperator: 'Operator pieniędzy mobilnych',
      bank: 'Bank',
      correctEmailAddressTip: 'Wprowadź poprawny adres e-mail'
    },
    common: {
      uploadImgErrorTip: 'Można przesyłać tylko obrazy',
      inputMessageTip: 'Proszę wypełnić treść',
      sendSuccessTip: 'Wysłanie powiodło się',
      sendErrorTip: 'Wysłanie nie powiodło się',
      inputPlaceholder: 'Proszę wprowadzić {field}',
      selectPlaceholder: 'Proszę wybrać {field}'
    }
  }
}
