export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: 'Página de pago seguro',
      description: 'Mantenemos su información financiera encriptada de manera segura',
      methodErrorTips: 'Lo sentimos, el país que ha seleccionado no está soportado actualmente. Por favor, seleccione otro país e inténtelo de nuevo.',
      recommendation: 'Recomendación',
      cardNumber: 'Número de tarjeta',
      cardName: 'Nombre en la tarjeta',
      cvv: 'CVV',
      expiredDate: 'Fecha de vencimiento',
      billingInfo: 'Información de facturación',
      country: 'País',
      state: 'Estado',
      firstName: 'Nombre',
      lastName: 'Apellido',
      document: 'Documento',
      city: 'Ciudad',
      address: 'Dirección',
      streetNumber: 'Número de calle',
      zipCode: 'Código postal',
      email: 'Correo electrónico',
      phone: 'Número de teléfono',
      pay: 'PAGAR',
      choosePaymentMethod: 'Elige método de pago',
      continue: 'Continuar',
      later: 'Más tarde',
      returnToMerchant: 'Cancelar y volver al comerciante',
      resultToMerchant: 'Volver al comerciante',
      orderId: 'ID de pedido',
      orderAmount: 'Monto del pedido',
      billingDescriptor: 'Descripción de la facturación',
      goodsName: 'Nombre del producto',
      timeLimit: 'Complete el pago dentro del límite de tiempo, de lo contrario, la página expirará',
      otherPaymentMethods: 'Otros métodos de pago',
      close: 'Cerrar',
      paymentErrorTitle: 'Pago fallido',
      paymentErrorTip: 'Su pago no se procesó correctamente. Por favor, inténtelo de nuevo.',
      paymentFailed: 'El proceso de pago ha fallado',
      continueShopping: 'Seguir comprando',
      paymentSuccess: 'El proceso de pago se ha completado con éxito',
      paymentPending: 'Pago enviado, confirmando',
      correctDateTip: 'Introduzca una fecha de vencimiento válida, por favor',
      correctCardNumberTip: 'Introduzca un número de tarjeta válido, por favor',
      correctSecurityCodeTip: 'Por favor, introduzca un código de seguridad válido',
      mobileMoneyOperator: 'Operador de dinero móvil',
      bank: 'Banco',
      correctEmailAddressTip: 'Por favor, ingrese una dirección de correo electrónico válida'
    },
    common: {
      uploadImgErrorTip: 'Solo se permite subir imágenes',
      inputMessageTip: 'Por favor, rellene el contenido',
      sendSuccessTip: 'Envío exitoso',
      sendErrorTip: 'Error al enviar',
      inputPlaceholder: 'Por favor, introduzca {field}',
      selectPlaceholder: 'Por favor, seleccione {field}'
    }
  }
}
