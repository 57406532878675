import { render, staticRenderFns } from "./index.vue?vue&type=template&id=ca759a12&"
var script = {}
import style0 from "./index.vue?vue&type=style&index=0&id=ca759a12&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.10.1_cache-loader@4.1.0_webpack@4.46.0__css-loader@3.6.0_webpack@4.46.0___8cdfb8a30e6790701421bf10ca45d28a/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports