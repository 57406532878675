export default {
  message: {
    title: 'Platform官方客服',
    dispute: {
      id: '争议ID',
      createdAt: '发起时间',
      orderDetails: '订单详情',
      inArbitration: '仲裁中',
      serialNumber: '流水号',
      orderNumber: '商户订单号',
      txnAmount: '交易金额',
      enableRefundAmount: '可退金额',
      holderEmail: '持卡人邮箱',
      holderName: '持卡人姓名',
      txnTime: '交易时间',
      txnWebsite: '交易网站',
      productInfo: '产品信息',
      endDispute: '关闭争议',
      applyArbitration: '申请仲裁',
      reset: '重置',
      send: '发送',
      apply: '争议发起',
      cardNoLast4: '卡号末4位',
      type: '争议类型',
      claims: '争议诉求',
      claimsPlaceholder: '请输入您争议的主要原因，以及期望的解决方案',
      nameHello: '尊敬的{name}, 您好',
      orderInfo: '您于<span class="date"> {date} </span> 在<a href="{link}"  class="website" target="_blank"> {website} </a>网站上有一笔<span class="amount"> {currency} {amount} </span>的消费',
      tip: '如果您对本笔订单有任何质疑，请在下方录入并发送争议信息',
      systemMessage: '系统消息',
      typeOptions: {
        unknow: '未知',
        fraud: '欺诈',
        unauthorized: '未授权',
        wrongGoods: '货不对版',
        productNotReceived: '未收到货',
        defectiveMerchandise: '商品有缺陷',
        incorrectTransaction: '错误订单',
        wrongAddress: '物流地址错误',
        creditNotProcessed: '未处理退款'
      },
      status: {
        0: '协调中',
        1: '仲裁中',
        3: '已关闭'
      },
      applyArbitrationSuccess: '您已成功申请仲裁。感谢您的使用！',
      endDisputeSuccess: '您已关闭此争议。感谢您的使用！',
      comfirm: '确认'
    },
    checkout: {
      title: '安全支付页面',
      description: '我们对您的财务信息进行安全加密',
      methodErrorTips: '很抱歉,您所选中的国家暂不支持,请更换其他国家重试。',
      recommendation: '推荐',
      cardNumber: '卡号',
      cardName: '卡上姓名',
      cvv: '信用卡安全码',
      expiredDate: '到期日期',
      billingInfo: '账单信息',
      country: '国家',
      state: '州/省',
      firstName: '名字',
      lastName: '姓氏',
      document: '文件/证件',
      city: '城市',
      address: '地址',
      streetNumber: '街道号码',
      zipCode: '邮政编码',
      email: '电子邮件',
      phone: '电话号码',
      pay: '支付',
      choosePaymentMethod: '选择支付方式',
      continue: '继续',
      later: '稍后',
      returnToMerchant: '取消并返回商家',
      resultToMerchant: '返回商家',
      orderId: '订单 ID',
      orderAmount: '订单金额',
      billingDescriptor: '账单描述',
      goodsName: '商品名称',
      timeLimit: '请在限时内完成支付，否则页面将超时',
      otherPaymentMethods: '其他支付方式',
      close: '关闭',
      paymentErrorTitle: '支付失败',
      paymentErrorTip: '您的支付未成功处理。请重试。',
      paymentFailed: '支付流程失败',
      continueShopping: '继续购物',
      paymentSuccess: '支付流程已成功完成',
      paymentPending: '已发送支付，正在确认',
      correctDateTip: '请输入有效的到期日期',
      correctCardNumberTip: '请输入有效的卡号',
      correctSecurityCodeTip: '请输入有效的安全码',
      mobileMoneyOperator: '移动货币运营商',
      bank: '银行',
      correctEmailAddressTip: '请输入正确的邮箱'
    },
    common: {
      uploadImgErrorTip: '只能上传图片',
      inputMessageTip: '请填写内容',
      sendSuccessTip: '发送成功',
      sendErrorTip: '发送失败',
      inputPlaceholder: '请输入{field}',
      selectPlaceholder: '请选择{field}'
    }
  }
}
