export default {
  message: {
    title: 'Official Customer Service',
    dispute: {
      id: 'Dispute ID',
      createdAt: 'Initiation Time',
      orderDetails: 'Order Details',
      inArbitration: 'In Arbitration',
      serialNumber: 'Serial Number',
      orderNumber: 'Order Number',
      txnAmount: 'Total Amount',
      enableRefundAmount: 'Refundable Amount',
      holderEmail: 'Consumer Email',
      holderName: 'Consumer Name',
      txnTime: 'Order Date',
      txnWebsite: 'Transaction Website',
      productInfo: 'Products',
      endDispute: 'Close Dispute',
      applyArbitration: 'Apply For Arbitration',
      reset: 'Reset',
      send: 'Send',
      apply: 'Initiate Disputes',
      cardNoLast4: 'Last 4 Digits Of Card Number',
      type: 'Dispute Type',
      claims: 'Claims',
      claimsPlaceholder: 'Please enter the main reason for your dispute and the expected solution',
      nameHello: 'Dear {name}',
      orderInfo: 'You made a <span class="amount"> {currency} {amount} </span> purchase on <a href="{link}" target="_blank" class="website"> {website} </a> from <span class="date"> {date} </span>',
      tip: 'If you have any questions about this order, please enter and send dispute information',
      systemMessage: 'System messages',
      typeOptions: {
        unknow: 'Unknow',
        fraud: 'Fraud',
        unauthorized: 'Unauthorized',
        wrongGoods: 'Products Discrepancies',
        productNotReceived: 'Product Not Received',
        defectiveMerchandise: 'Defective Merchandise',
        incorrectTransaction: 'Incorrect Transaction',
        wrongAddress: 'Wrong address',
        creditNotProcessed: 'Credit Not Processed'
      },
      status: {
        0: 'In Coordination',
        1: 'In Arbitration',
        // 2: '已仲裁',去掉这个状态，不翻译
        3: 'Ended'
      },
      applyArbitrationSuccess: 'You have applied for arbitration',
      endDisputeSuccess: 'You have ended this dispute',
      comfirm: 'Submit'
    },
    checkout: {
      title: 'Pagina di pagamento sicuro',
      description: 'Manteniamo le tue informazioni finanziarie crittografate in modo sicuro',
      methodErrorTips: 'Spiacenti, il paese che hai selezionato non è attualmente supportato. Si prega di selezionare un altro paese e riprovare.',
      recommendation: 'Raccomandazione',
      cardNumber: 'Numero di carta',
      cardName: 'Nome sulla carta',
      cvv: 'CVV',
      expiredDate: 'Data di scadenza',
      billingInfo: 'Informazioni di fatturazione',
      country: 'Paese',
      state: 'Stato',
      firstName: 'Nome',
      lastName: 'Cognome',
      document: 'Documento',
      city: 'Città',
      address: 'Indirizzo',
      streetNumber: 'Numero civico',
      zipCode: 'Codice postale',
      email: 'E-mail',
      phone: 'Numero di telefono',
      pay: 'PAGA',
      choosePaymentMethod: 'Scegli il metodo di pagamento',
      continue: 'Continua',
      later: 'Più tardi',
      returnToMerchant: 'Annulla e ritorna al commerciante',
      resultToMerchant: 'Ritorna al commerciante',
      orderId: 'ID Ordine',
      orderAmount: 'Importo Ordine',
      billingDescriptor: 'Descrittore di fatturazione',
      goodsName: 'Nome del prodotto',
      timeLimit: 'Si prega di completare il pagamento entro il limite di tempo, altrimenti la pagina scadrà',
      otherPaymentMethods: 'Altri metodi di pagamento',
      close: 'Chiudi',
      paymentErrorTitle: 'Pagamento fallito',
      paymentErrorTip: 'Il tuo pagamento non è stato elaborato con successo. Si prega di riprovare.',
      paymentFailed: 'Il processo di pagamento è fallito',
      continueShopping: 'Continua lo shopping',
      paymentSuccess: 'Il processo di pagamento è stato completato con successo',
      paymentPending: 'Pagamento inviato, in attesa di conferma',
      correctDateTip: 'Inserisci una data di scadenza valida, per favore',
      correctCardNumberTip: 'Inserisci un numero di carta valido, per favore',
      correctSecurityCodeTip: 'Si prega di inserire un codice di sicurezza valido',
      mobileMoneyOperator: 'Operatore di denaro mobile',
      bank: 'Banca',
      correctEmailAddressTip: 'Si prega di inserire un indirizzo e-mail valido'
    },
    common: {
      uploadImgErrorTip: 'È consentito caricare solo immagini',
      inputMessageTip: 'Si prega di compilare il contenuto',
      sendSuccessTip: 'Invio riuscito',
      sendErrorTip: 'Invio fallito',
      inputPlaceholder: 'Si prega di inserire {field}',
      selectPlaceholder: 'Si prega di selezionare {field}'
    }
  }
}
